var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c(
        "div",
        { staticClass: "navBtnTop" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeStep !== 0,
                  expression: "activeStep !== 0",
                },
              ],
              attrs: { size: "mini" },
              on: { click: _vm.lastStep },
            },
            [_vm._v("上一步")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeStep !== 3,
                  expression: "activeStep !== 3",
                },
              ],
              attrs: { size: "mini" },
              on: { click: _vm.nextStep },
            },
            [_vm._v("下一步")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeStep === 3,
                  expression: "activeStep === 3",
                },
              ],
              attrs: { type: "primary", size: "mini", disabled: _vm.disabled },
              on: {
                click: function ($event) {
                  return _vm.submitForm(true)
                },
              },
            },
            [_vm._v("保存 ")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeStep === 3,
                  expression: "activeStep === 3",
                },
              ],
              attrs: { type: "primary", size: "mini", disabled: _vm.disabled },
              on: { click: _vm.addBill },
            },
            [_vm._v("保存并新增 ")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeStep === 3,
                  expression: "activeStep === 3",
                },
              ],
              attrs: {
                icon: "iconfont icon-a-weibiaoti-1_huaban1fuben28",
                size: "mini",
                disabled: _vm.showAudit,
                type: "success",
              },
              on: {
                click: function ($event) {
                  return _vm.auditBill("审核")
                },
              },
            },
            [_vm._v("审核")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showAdd,
                  expression: "showAdd",
                },
              ],
              staticClass: "marL10",
              attrs: { type: "primary", icon: "el-icon-plus", size: "mini" },
              on: { click: _vm.handleAdd },
            },
            [_vm._v("新增")]
          ),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.getQuit } },
            [_vm._v("退出")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.precisionForm, "label-width": "70px" },
            },
            [
              _c(
                "cardTitleCom",
                {
                  attrs: {
                    cardTitle: "当前进度",
                    auditOrder: _vm.auditOrder,
                    noAuditOrder: _vm.noAuditOrder,
                    stopOrder: _vm.stopOrder,
                    deleteOrder: _vm.deleteOrder,
                    disableOrder: _vm.disableOrder,
                  },
                },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c(
                      "div",
                      { staticClass: "theSteps" },
                      [
                        _c(
                          "el-steps",
                          {
                            attrs: {
                              active: _vm.activeStep,
                              "finish-status": "success",
                            },
                          },
                          [
                            _c("el-step", {
                              staticClass: "firstStep",
                              attrs: { title: "筛选对象" },
                            }),
                            _c("el-step", {
                              staticClass: "secondStep",
                              attrs: { title: "营销对象确认" },
                            }),
                            _c("el-step", {
                              staticClass: "thirdStep",
                              attrs: { title: "设置礼包" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "cardTitleCom",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeStep === 0,
                      expression: "activeStep === 0",
                    },
                  ],
                  attrs: { cardTitle: "基本信息" },
                },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c(
                      "div",
                      { staticClass: "modifyType" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "方案编号", prop: "billNo" } },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth marR15",
                              attrs: {
                                disabled: true,
                                size: "mini",
                                placeholder: "方案编号",
                              },
                              model: {
                                value: _vm.precisionForm.billNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.precisionForm, "billNo", $$v)
                                },
                                expression: "precisionForm.billNo",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "方案名称", prop: "billName" } },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                disabled: _vm.disabled,
                                size: "mini",
                                placeholder: "方案名称",
                              },
                              model: {
                                value: _vm.precisionForm.billName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.precisionForm, "billName", $$v)
                                },
                                expression: "precisionForm.billName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "备注",
                              prop: "billRemark",
                              "label-width": "60px",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "inputRemark",
                              attrs: {
                                disabled: _vm.disabled,
                                type: "textarea",
                                size: "mini",
                                placeholder: "备注长度介于 1 和 80 字符之间",
                                maxlength: "80",
                                autosize: { minRows: 1, maxRows: 5 },
                              },
                              model: {
                                value: _vm.precisionForm.billRemark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.precisionForm, "billRemark", $$v)
                                },
                                expression: "precisionForm.billRemark",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "cardTitleCom",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeStep === 0,
                      expression: "activeStep === 0",
                    },
                  ],
                  attrs: { cardTitle: "目标对象筛选" },
                },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c(
                      "div",
                      { staticClass: "tableContent" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "marB50",
                            attrs: {
                              label: "筛选目标用户",
                              prop: "precisionTargetType",
                              "label-width": "100px",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "inputWidth",
                                attrs: {
                                  placeholder: "目标会员",
                                  size: "mini",
                                  disabled: _vm.disabled,
                                },
                                model: {
                                  value: _vm.precisionForm.precisionTargetType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.precisionForm,
                                      "precisionTargetType",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "precisionForm.precisionTargetType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "所有会员", value: 2 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "指定会员", value: 1 },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.precisionForm.precisionTargetType == 1,
                                expression:
                                  "precisionForm.precisionTargetType == 1",
                              },
                            ],
                            staticClass: "filterCriteria",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "x-x marB50" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: { disabled: _vm.disabled },
                                    model: {
                                      value:
                                        _vm.precisionForm.isUseConsumeAction,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.precisionForm,
                                          "isUseConsumeAction",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "precisionForm.isUseConsumeAction",
                                    },
                                  },
                                  [_vm._v("按消费行为:")]
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.precisionForm.isUseConsumeAction,
                                        expression:
                                          "precisionForm.isUseConsumeAction",
                                      },
                                    ],
                                    staticClass: "filterCriteriaTwo",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "marB50 x-f" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[0]
                                                  .isSelected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[0],
                                                  "isSelected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[0].isSelected",
                                            },
                                          },
                                          [_vm._v("累计消费金额:")]
                                        ),
                                        _c(
                                          "el-radio-group",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[0].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[0].isSelected",
                                              },
                                            ],
                                            staticClass: "marL20 marR20",
                                            attrs: {
                                              size: "medium",
                                              disabled: _vm.disabled,
                                            },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[0]
                                                  .timeRangeType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[0],
                                                  "timeRangeType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[0].timeRangeType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 1 } },
                                              [_vm._v("近一周")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 2 } },
                                              [_vm._v("近一个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 3 } },
                                              [_vm._v("三个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 4 } },
                                              [_vm._v("半年")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 5 } },
                                              [_vm._v("自定义")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[0].isSelected &&
                                                  _vm.precisionForm
                                                    .detailItem[0]
                                                    .timeRangeType == 5,
                                                expression:
                                                  "\n                        precisionForm.detailItem[0].isSelected &&\n                        precisionForm.detailItem[0].timeRangeType == 5\n                      ",
                                              },
                                            ],
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticClass: "marR20",
                                              attrs: {
                                                type: "datetimerange",
                                                "range-separator": "至",
                                                "start-placeholder": "开始日期",
                                                "end-placeholder": "结束日期",
                                                "default-time": [
                                                  "00:00:00",
                                                  "23:59:59",
                                                ],
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm:ss",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value: _vm.datePicker0,
                                                callback: function ($$v) {
                                                  _vm.datePicker0 = $$v
                                                },
                                                expression: "datePicker0",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[0].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[0].isSelected",
                                              },
                                            ],
                                            staticClass: "x-f",
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass:
                                                "inputWidthPrecision",
                                              attrs: {
                                                type: "number",
                                                size: "mini",
                                                placeholder: "请输入内容",
                                                disabled: _vm.disabled,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.inputLimit(
                                                    $event,
                                                    8,
                                                    4,
                                                    _vm.precisionForm
                                                      .detailItem[0],
                                                    "begValue"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[0].begValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.precisionForm
                                                      .detailItem[0],
                                                    "begValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "precisionForm.detailItem[0].begValue",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "marR10 marL10" },
                                              [_vm._v("——")]
                                            ),
                                            _c("el-input", {
                                              staticClass:
                                                "inputWidthPrecision",
                                              attrs: {
                                                type: "number",
                                                placeholder: "请输入内容",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.inputLimit(
                                                    $event,
                                                    8,
                                                    4,
                                                    _vm.precisionForm
                                                      .detailItem[0],
                                                    "endValue"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[0].endValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.precisionForm
                                                      .detailItem[0],
                                                    "endValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "precisionForm.detailItem[0].endValue",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "marL10" },
                                              [_vm._v("元")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "marB50 x-f" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[1]
                                                  .isSelected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[1],
                                                  "isSelected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[1].isSelected",
                                            },
                                          },
                                          [_vm._v("平均消费金额:")]
                                        ),
                                        _c(
                                          "el-radio-group",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[1].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[1].isSelected",
                                              },
                                            ],
                                            staticClass: "marL20 marR20",
                                            attrs: {
                                              size: "medium",
                                              disabled: _vm.disabled,
                                            },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[1]
                                                  .timeRangeType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[1],
                                                  "timeRangeType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[1].timeRangeType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 1 } },
                                              [_vm._v("近一周")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 2 } },
                                              [_vm._v("近一个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 3 } },
                                              [_vm._v("三个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 4 } },
                                              [_vm._v("半年")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 5 } },
                                              [_vm._v("自定义")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[1].isSelected &&
                                                  _vm.precisionForm
                                                    .detailItem[1]
                                                    .timeRangeType == 5,
                                                expression:
                                                  "\n                        precisionForm.detailItem[1].isSelected &&\n                        precisionForm.detailItem[1].timeRangeType == 5\n                      ",
                                              },
                                            ],
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticClass: "marR20",
                                              attrs: {
                                                type: "datetimerange",
                                                "range-separator": "至",
                                                "start-placeholder": "开始日期",
                                                "end-placeholder": "结束日期",
                                                "default-time": [
                                                  "00:00:00",
                                                  "23:59:59",
                                                ],
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm:ss",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value: _vm.datePicker1,
                                                callback: function ($$v) {
                                                  _vm.datePicker1 = $$v
                                                },
                                                expression: "datePicker1",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[1].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[1].isSelected",
                                              },
                                            ],
                                            staticClass: "x-f",
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass:
                                                "inputWidthPrecision",
                                              attrs: {
                                                type: "number",
                                                placeholder: "请输入内容",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.inputLimit(
                                                    $event,
                                                    8,
                                                    4,
                                                    _vm.precisionForm
                                                      .detailItem[1],
                                                    "begValue"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[1].begValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.precisionForm
                                                      .detailItem[1],
                                                    "begValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "precisionForm.detailItem[1].begValue",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "marR10 marL10" },
                                              [_vm._v("——")]
                                            ),
                                            _c("el-input", {
                                              staticClass:
                                                "inputWidthPrecision",
                                              attrs: {
                                                type: "number",
                                                size: "mini",
                                                placeholder: "请输入内容",
                                                disabled: _vm.disabled,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.inputLimit(
                                                    $event,
                                                    8,
                                                    4,
                                                    _vm.precisionForm
                                                      .detailItem[1],
                                                    "endValue"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[1].endValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.precisionForm
                                                      .detailItem[1],
                                                    "endValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "precisionForm.detailItem[1].endValue",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "marL10" },
                                              [_vm._v("元")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "marB50 x-f" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[2]
                                                  .isSelected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[2],
                                                  "isSelected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[2].isSelected",
                                            },
                                          },
                                          [_vm._v("累计消费次数:")]
                                        ),
                                        _c(
                                          "el-radio-group",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[2].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[2].isSelected",
                                              },
                                            ],
                                            staticClass: "marL20 marR20",
                                            attrs: {
                                              size: "medium",
                                              disabled: _vm.disabled,
                                            },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[2]
                                                  .timeRangeType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[2],
                                                  "timeRangeType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[2].timeRangeType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 1 } },
                                              [_vm._v("近一周")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 2 } },
                                              [_vm._v("近一个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 3 } },
                                              [_vm._v("三个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 4 } },
                                              [_vm._v("半年")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 5 } },
                                              [_vm._v("自定义")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[2].isSelected &&
                                                  _vm.precisionForm
                                                    .detailItem[2]
                                                    .timeRangeType == 5,
                                                expression:
                                                  "\n                        precisionForm.detailItem[2].isSelected &&\n                        precisionForm.detailItem[2].timeRangeType == 5\n                      ",
                                              },
                                            ],
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticClass: "marR20",
                                              attrs: {
                                                type: "datetimerange",
                                                "range-separator": "至",
                                                "start-placeholder": "开始日期",
                                                "end-placeholder": "结束日期",
                                                "default-time": [
                                                  "00:00:00",
                                                  "23:59:59",
                                                ],
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm:ss",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value: _vm.datePicker2,
                                                callback: function ($$v) {
                                                  _vm.datePicker2 = $$v
                                                },
                                                expression: "datePicker2",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[2].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[2].isSelected",
                                              },
                                            ],
                                            staticClass: "x-f",
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass:
                                                "inputWidthPrecision",
                                              attrs: {
                                                placeholder: "请输入内容",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.inputLimit(
                                                    $event,
                                                    4,
                                                    0,
                                                    _vm.precisionForm
                                                      .detailItem[2],
                                                    "begValue"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[2].begValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.precisionForm
                                                      .detailItem[2],
                                                    "begValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "precisionForm.detailItem[2].begValue",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "marR10 marL10" },
                                              [_vm._v("——")]
                                            ),
                                            _c("el-input", {
                                              staticClass:
                                                "inputWidthPrecision",
                                              attrs: {
                                                placeholder: "请输入内容",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.inputLimit(
                                                    $event,
                                                    4,
                                                    0,
                                                    _vm.precisionForm
                                                      .detailItem[2],
                                                    "endValue"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[2].endValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.precisionForm
                                                      .detailItem[2],
                                                    "endValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "precisionForm.detailItem[2].endValue",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "marL10" },
                                              [_vm._v("次")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "marB50 x-f" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[3]
                                                  .isSelected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[3],
                                                  "isSelected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[3].isSelected",
                                            },
                                          },
                                          [_vm._v("未消费时长:")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[3].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[3].isSelected",
                                              },
                                            ],
                                            staticClass: "x-f",
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass:
                                                "inputWidthPrecision marL33",
                                              attrs: {
                                                placeholder: "请输入内容",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.inputLimit(
                                                    $event,
                                                    4,
                                                    0,
                                                    _vm.precisionForm
                                                      .detailItem[3],
                                                    "begValue"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[3].begValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.precisionForm
                                                      .detailItem[3],
                                                    "begValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "precisionForm.detailItem[3].begValue",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "marR10 marL10" },
                                              [_vm._v(" —— ")]
                                            ),
                                            _c("el-input", {
                                              staticClass:
                                                "inputWidthPrecision",
                                              attrs: {
                                                placeholder: "请输入内容",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.inputLimit(
                                                    $event,
                                                    4,
                                                    0,
                                                    _vm.precisionForm
                                                      .detailItem[3],
                                                    "endValue"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[3].endValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.precisionForm
                                                      .detailItem[3],
                                                    "endValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "precisionForm.detailItem[3].endValue",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "marL10" },
                                              [_vm._v("天")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "x-f" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[4]
                                                  .isSelected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[4],
                                                  "isSelected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[4].isSelected",
                                            },
                                          },
                                          [_vm._v("会员消费门店:")]
                                        ),
                                        _c(
                                          "el-radio-group",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[4].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[4].isSelected",
                                              },
                                            ],
                                            staticClass: "marL20 marR20",
                                            attrs: {
                                              size: "medium",
                                              disabled: _vm.disabled,
                                            },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[4]
                                                  .timeRangeType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[4],
                                                  "timeRangeType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[4].timeRangeType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 1 } },
                                              [_vm._v("近一周")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 2 } },
                                              [_vm._v("近一个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 3 } },
                                              [_vm._v("三个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 4 } },
                                              [_vm._v("半年")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 5 } },
                                              [_vm._v("自定义")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[4].isSelected &&
                                                  _vm.precisionForm
                                                    .detailItem[4]
                                                    .timeRangeType == 5,
                                                expression:
                                                  "\n                        precisionForm.detailItem[4].isSelected &&\n                        precisionForm.detailItem[4].timeRangeType == 5\n                      ",
                                              },
                                            ],
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticClass: "marR20",
                                              attrs: {
                                                type: "datetimerange",
                                                "range-separator": "至",
                                                "start-placeholder": "开始日期",
                                                "end-placeholder": "结束日期",
                                                "default-time": [
                                                  "00:00:00",
                                                  "23:59:59",
                                                ],
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm:ss",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value: _vm.datePicker4,
                                                callback: function ($$v) {
                                                  _vm.datePicker4 = $$v
                                                },
                                                expression: "datePicker4",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[4].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[4].isSelected",
                                              },
                                            ],
                                            staticClass: "x-f",
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  disabled: _vm.disabled,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.getOpenShops(4)
                                                  },
                                                },
                                              },
                                              [_vm._v("选择门店")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.precisionForm
                                                        .detailItem[4].shopItem
                                                        .length > 0,
                                                    expression:
                                                      "\n                          precisionForm.detailItem[4].shopItem.length > 0\n                        ",
                                                  },
                                                ],
                                                staticClass: "marL10",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.precisionForm
                                                      .detailItem[4].shopItem
                                                      .length
                                                  ) + "个"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "x-x marB50" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: { disabled: _vm.disabled },
                                    model: {
                                      value: _vm.precisionForm.isUseGoodsLike,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.precisionForm,
                                          "isUseGoodsLike",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "precisionForm.isUseGoodsLike",
                                    },
                                  },
                                  [_vm._v("按商品喜欢:")]
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.precisionForm.isUseGoodsLike,
                                        expression:
                                          "precisionForm.isUseGoodsLike",
                                      },
                                    ],
                                    staticClass: "filterCriteriaTwo",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "marB50 x-f" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[5]
                                                  .isSelected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[5],
                                                  "isSelected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[5].isSelected",
                                            },
                                          },
                                          [_vm._v("消费过的商品:")]
                                        ),
                                        _c(
                                          "el-radio-group",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[5].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[5].isSelected",
                                              },
                                            ],
                                            staticClass: "marL20 marR20",
                                            attrs: {
                                              size: "medium",
                                              disabled: _vm.disabled,
                                            },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[5]
                                                  .timeRangeType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[5],
                                                  "timeRangeType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[5].timeRangeType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 1 } },
                                              [_vm._v("近一周")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 2 } },
                                              [_vm._v("近一个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 3 } },
                                              [_vm._v("三个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 4 } },
                                              [_vm._v("半年")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 5 } },
                                              [_vm._v("自定义")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[5].isSelected &&
                                                  _vm.precisionForm
                                                    .detailItem[5]
                                                    .timeRangeType == 5,
                                                expression:
                                                  "\n                        precisionForm.detailItem[5].isSelected &&\n                        precisionForm.detailItem[5].timeRangeType == 5\n                      ",
                                              },
                                            ],
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticClass: "marR20",
                                              attrs: {
                                                type: "datetimerange",
                                                "range-separator": "至",
                                                "start-placeholder": "开始日期",
                                                "end-placeholder": "结束日期",
                                                "default-time": [
                                                  "00:00:00",
                                                  "23:59:59",
                                                ],
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm:ss",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value: _vm.datePicker5,
                                                callback: function ($$v) {
                                                  _vm.datePicker5 = $$v
                                                },
                                                expression: "datePicker5",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[5].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[5].isSelected",
                                              },
                                            ],
                                            staticClass: "x-f",
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  disabled: _vm.disabled,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.getOpenGoods(5)
                                                  },
                                                },
                                              },
                                              [_vm._v("选择商品")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.precisionForm
                                                        .detailItem[5].goodsItem
                                                        .length > 0,
                                                    expression:
                                                      "\n                          precisionForm.detailItem[5].goodsItem.length > 0\n                        ",
                                                  },
                                                ],
                                                staticClass: "marL10",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.precisionForm
                                                      .detailItem[5].goodsItem
                                                      .length
                                                  ) + "种"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "marB50 x-f" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[6]
                                                  .isSelected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[6],
                                                  "isSelected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[6].isSelected",
                                            },
                                          },
                                          [_vm._v("消费过的品类:")]
                                        ),
                                        _c(
                                          "el-radio-group",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[6].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[6].isSelected",
                                              },
                                            ],
                                            staticClass: "marL20 marR20",
                                            attrs: {
                                              size: "medium",
                                              disabled: _vm.disabled,
                                            },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[6]
                                                  .timeRangeType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[6],
                                                  "timeRangeType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[6].timeRangeType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 1 } },
                                              [_vm._v("近一周")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 2 } },
                                              [_vm._v("近一个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 3 } },
                                              [_vm._v("三个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 4 } },
                                              [_vm._v("半年")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 5 } },
                                              [_vm._v("自定义")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[6].isSelected &&
                                                  _vm.precisionForm
                                                    .detailItem[6]
                                                    .timeRangeType == 5,
                                                expression:
                                                  "\n                        precisionForm.detailItem[6].isSelected &&\n                        precisionForm.detailItem[6].timeRangeType == 5\n                      ",
                                              },
                                            ],
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticClass: "marR20",
                                              attrs: {
                                                type: "datetimerange",
                                                "range-separator": "至",
                                                "start-placeholder": "开始日期",
                                                "end-placeholder": "结束日期",
                                                "default-time": [
                                                  "00:00:00",
                                                  "23:59:59",
                                                ],
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm:ss",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value: _vm.datePicker6,
                                                callback: function ($$v) {
                                                  _vm.datePicker6 = $$v
                                                },
                                                expression: "datePicker6",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[6].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[6].isSelected",
                                              },
                                            ],
                                            staticClass: "x-f",
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  disabled: _vm.disabled,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.getOpenCategorys(
                                                      6
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("选择品类")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.precisionForm
                                                        .detailItem[6].goodsItem
                                                        .length > 0,
                                                    expression:
                                                      "\n                          precisionForm.detailItem[6].goodsItem.length > 0\n                        ",
                                                  },
                                                ],
                                                staticClass: "marL10",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.precisionForm
                                                      .detailItem[6].goodsItem
                                                      .length
                                                  ) + "种"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "marB50 x-f" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[7]
                                                  .isSelected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[7],
                                                  "isSelected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[7].isSelected",
                                            },
                                          },
                                          [_vm._v("消费过的品牌:")]
                                        ),
                                        _c(
                                          "el-radio-group",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[7].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[7].isSelected",
                                              },
                                            ],
                                            staticClass: "marL20 marR20",
                                            attrs: {
                                              size: "medium",
                                              disabled: _vm.disabled,
                                            },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[7]
                                                  .timeRangeType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[7],
                                                  "timeRangeType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[7].timeRangeType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 1 } },
                                              [_vm._v("近一周")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 2 } },
                                              [_vm._v("近一个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 3 } },
                                              [_vm._v("三个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 4 } },
                                              [_vm._v("半年")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 5 } },
                                              [_vm._v("自定义")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[7].isSelected &&
                                                  _vm.precisionForm
                                                    .detailItem[7]
                                                    .timeRangeType == 5,
                                                expression:
                                                  "\n                        precisionForm.detailItem[7].isSelected &&\n                        precisionForm.detailItem[7].timeRangeType == 5\n                      ",
                                              },
                                            ],
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticClass: "marR20",
                                              attrs: {
                                                type: "datetimerange",
                                                "range-separator": "至",
                                                "start-placeholder": "开始日期",
                                                "end-placeholder": "结束日期",
                                                "default-time": [
                                                  "00:00:00",
                                                  "23:59:59",
                                                ],
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm:ss",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value: _vm.datePicker7,
                                                callback: function ($$v) {
                                                  _vm.datePicker7 = $$v
                                                },
                                                expression: "datePicker7",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[7].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[7].isSelected",
                                              },
                                            ],
                                            staticClass: "x-f",
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                  type: "text",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.getOpenBrands(7)
                                                  },
                                                },
                                              },
                                              [_vm._v("选择品牌")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.precisionForm
                                                        .detailItem[7].goodsItem
                                                        .length > 0,
                                                    expression:
                                                      "\n                          precisionForm.detailItem[7].goodsItem.length > 0\n                        ",
                                                  },
                                                ],
                                                staticClass: "marL10",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.precisionForm
                                                      .detailItem[7].goodsItem
                                                      .length
                                                  ) + "种"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "marB50 x-f" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[8]
                                                  .isSelected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[8],
                                                  "isSelected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[8].isSelected",
                                            },
                                          },
                                          [_vm._v("未消费的商品:")]
                                        ),
                                        _c(
                                          "el-radio-group",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[8].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[8].isSelected",
                                              },
                                            ],
                                            staticClass: "marL20 marR20",
                                            attrs: {
                                              size: "medium",
                                              disabled: _vm.disabled,
                                            },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[8]
                                                  .timeRangeType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[8],
                                                  "timeRangeType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[8].timeRangeType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 1 } },
                                              [_vm._v("近一周")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 2 } },
                                              [_vm._v("近一个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 3 } },
                                              [_vm._v("三个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 4 } },
                                              [_vm._v("半年")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 5 } },
                                              [_vm._v("自定义")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[8].isSelected &&
                                                  _vm.precisionForm
                                                    .detailItem[8]
                                                    .timeRangeType == 5,
                                                expression:
                                                  "\n                        precisionForm.detailItem[8].isSelected &&\n                        precisionForm.detailItem[8].timeRangeType == 5\n                      ",
                                              },
                                            ],
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticClass: "marR20",
                                              attrs: {
                                                type: "datetimerange",
                                                "range-separator": "至",
                                                "start-placeholder": "开始日期",
                                                "end-placeholder": "结束日期",
                                                "default-time": [
                                                  "00:00:00",
                                                  "23:59:59",
                                                ],
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm:ss",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value: _vm.datePicker8,
                                                callback: function ($$v) {
                                                  _vm.datePicker8 = $$v
                                                },
                                                expression: "datePicker8",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[8].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[8].isSelected",
                                              },
                                            ],
                                            staticClass: "x-f",
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                  type: "text",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.getOpenGoods(8)
                                                  },
                                                },
                                              },
                                              [_vm._v("选择商品")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.precisionForm
                                                        .detailItem[8].goodsItem
                                                        .length > 0,
                                                    expression:
                                                      "\n                          precisionForm.detailItem[8].goodsItem.length > 0\n                        ",
                                                  },
                                                ],
                                                staticClass: "marL10",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.precisionForm
                                                      .detailItem[8].goodsItem
                                                      .length
                                                  ) + "种"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "marB50 x-f" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[9]
                                                  .isSelected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[9],
                                                  "isSelected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[9].isSelected",
                                            },
                                          },
                                          [_vm._v("未消费的品类:")]
                                        ),
                                        _c(
                                          "el-radio-group",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[9].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[9].isSelected",
                                              },
                                            ],
                                            staticClass: "marL20 marR20",
                                            attrs: {
                                              size: "medium",
                                              disabled: _vm.disabled,
                                            },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[9]
                                                  .timeRangeType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[9],
                                                  "timeRangeType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[9].timeRangeType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 1 } },
                                              [_vm._v("近一周")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 2 } },
                                              [_vm._v("近一个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 3 } },
                                              [_vm._v("三个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 4 } },
                                              [_vm._v("半年")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 5 } },
                                              [_vm._v("自定义")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[9].isSelected &&
                                                  _vm.precisionForm
                                                    .detailItem[9]
                                                    .timeRangeType == 5,
                                                expression:
                                                  "\n                        precisionForm.detailItem[9].isSelected &&\n                        precisionForm.detailItem[9].timeRangeType == 5\n                      ",
                                              },
                                            ],
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticClass: "marR20",
                                              attrs: {
                                                type: "datetimerange",
                                                "range-separator": "至",
                                                "start-placeholder": "开始日期",
                                                "end-placeholder": "结束日期",
                                                "default-time": [
                                                  "00:00:00",
                                                  "23:59:59",
                                                ],
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm:ss",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value: _vm.datePicker9,
                                                callback: function ($$v) {
                                                  _vm.datePicker9 = $$v
                                                },
                                                expression: "datePicker9",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[9].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[9].isSelected",
                                              },
                                            ],
                                            staticClass: "x-f",
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                  type: "text",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.getOpenCategorys(
                                                      9
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("选择品类")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.precisionForm
                                                        .detailItem[9].goodsItem
                                                        .length > 0,
                                                    expression:
                                                      "\n                          precisionForm.detailItem[9].goodsItem.length > 0\n                        ",
                                                  },
                                                ],
                                                staticClass: "marL10",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.precisionForm
                                                      .detailItem[9].goodsItem
                                                      .length
                                                  ) + "种"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "x-f" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[10]
                                                  .isSelected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[10],
                                                  "isSelected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[10].isSelected",
                                            },
                                          },
                                          [_vm._v("未消费的品牌:")]
                                        ),
                                        _c(
                                          "el-radio-group",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[10].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[10].isSelected",
                                              },
                                            ],
                                            staticClass: "marL20 marR20",
                                            attrs: {
                                              size: "medium",
                                              disabled: _vm.disabled,
                                            },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[10]
                                                  .timeRangeType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[10],
                                                  "timeRangeType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[10].timeRangeType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 1 } },
                                              [_vm._v("近一周")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 2 } },
                                              [_vm._v("近一个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 3 } },
                                              [_vm._v("三个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 4 } },
                                              [_vm._v("半年")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 5 } },
                                              [_vm._v("自定义")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[10]
                                                    .isSelected &&
                                                  _vm.precisionForm
                                                    .detailItem[10]
                                                    .timeRangeType == 5,
                                                expression:
                                                  "\n                        precisionForm.detailItem[10].isSelected &&\n                        precisionForm.detailItem[10].timeRangeType == 5\n                      ",
                                              },
                                            ],
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticClass: "marR20",
                                              attrs: {
                                                type: "datetimerange",
                                                "range-separator": "至",
                                                "start-placeholder": "开始日期",
                                                "end-placeholder": "结束日期",
                                                "default-time": [
                                                  "00:00:00",
                                                  "23:59:59",
                                                ],
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm:ss",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value: _vm.datePicker10,
                                                callback: function ($$v) {
                                                  _vm.datePicker10 = $$v
                                                },
                                                expression: "datePicker10",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[10].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[10].isSelected",
                                              },
                                            ],
                                            staticClass: "x-f",
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                  type: "text",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.getOpenBrands(10)
                                                  },
                                                },
                                              },
                                              [_vm._v("选择品牌")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.precisionForm
                                                        .detailItem[10]
                                                        .goodsItem.length > 0,
                                                    expression:
                                                      "\n                          precisionForm.detailItem[10].goodsItem.length > 0\n                        ",
                                                  },
                                                ],
                                                staticClass: "marL10",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.precisionForm
                                                      .detailItem[10].goodsItem
                                                      .length
                                                  ) + "种"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "x-x marB50" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: { disabled: _vm.disabled },
                                    model: {
                                      value: _vm.precisionForm.isUseVipAccount,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.precisionForm,
                                          "isUseVipAccount",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "precisionForm.isUseVipAccount",
                                    },
                                  },
                                  [_vm._v("按会员账户:")]
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.precisionForm.isUseVipAccount,
                                        expression:
                                          "precisionForm.isUseVipAccount",
                                      },
                                    ],
                                    staticClass: "filterCriteriaTwo",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "marB50 x-f" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[11]
                                                  .isSelected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[11],
                                                  "isSelected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[11].isSelected",
                                            },
                                          },
                                          [_vm._v("累计充值金额:")]
                                        ),
                                        _c(
                                          "el-radio-group",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[11].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[11].isSelected",
                                              },
                                            ],
                                            staticClass: "marL20 marR20",
                                            attrs: {
                                              size: "medium",
                                              disabled: _vm.disabled,
                                            },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[11]
                                                  .timeRangeType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[11],
                                                  "timeRangeType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[11].timeRangeType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 1 } },
                                              [_vm._v("近一周")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 2 } },
                                              [_vm._v("近一个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 3 } },
                                              [_vm._v("三个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 4 } },
                                              [_vm._v("半年")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 5 } },
                                              [_vm._v("自定义")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[11]
                                                    .isSelected &&
                                                  _vm.precisionForm
                                                    .detailItem[11]
                                                    .timeRangeType == 5,
                                                expression:
                                                  "\n                        precisionForm.detailItem[11].isSelected &&\n                        precisionForm.detailItem[11].timeRangeType == 5\n                      ",
                                              },
                                            ],
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticClass: "marR20",
                                              attrs: {
                                                type: "datetimerange",
                                                "range-separator": "至",
                                                "start-placeholder": "开始日期",
                                                "end-placeholder": "结束日期",
                                                "default-time": [
                                                  "00:00:00",
                                                  "23:59:59",
                                                ],
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm:ss",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value: _vm.datePicker11,
                                                callback: function ($$v) {
                                                  _vm.datePicker11 = $$v
                                                },
                                                expression: "datePicker11",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[11].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[11].isSelected",
                                              },
                                            ],
                                            staticClass: "x-f",
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass:
                                                "inputWidthPrecision",
                                              attrs: {
                                                type: "number",
                                                placeholder: "请输入内容",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.inputLimit(
                                                    $event,
                                                    8,
                                                    4,
                                                    _vm.precisionForm
                                                      .detailItem[11],
                                                    "begValue"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[11].begValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.precisionForm
                                                      .detailItem[11],
                                                    "begValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "precisionForm.detailItem[11].begValue",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "marR10 marL10" },
                                              [_vm._v("——")]
                                            ),
                                            _c("el-input", {
                                              staticClass:
                                                "inputWidthPrecision",
                                              attrs: {
                                                type: "number",
                                                placeholder: "请输入内容",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.inputLimit(
                                                    $event,
                                                    8,
                                                    4,
                                                    _vm.precisionForm
                                                      .detailItem[11],
                                                    "endValue"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[11].endValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.precisionForm
                                                      .detailItem[11],
                                                    "endValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "precisionForm.detailItem[11].endValue",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "marL10" },
                                              [_vm._v("元")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "marB50 x-f" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[12]
                                                  .isSelected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[12],
                                                  "isSelected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[12].isSelected",
                                            },
                                          },
                                          [_vm._v("累计充值次数:")]
                                        ),
                                        _c(
                                          "el-radio-group",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[12].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[12].isSelected",
                                              },
                                            ],
                                            staticClass: "marL20 marR20",
                                            attrs: {
                                              size: "medium",
                                              disabled: _vm.disabled,
                                            },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[12]
                                                  .timeRangeType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[12],
                                                  "timeRangeType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[12].timeRangeType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 1 } },
                                              [_vm._v("近一周")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 2 } },
                                              [_vm._v("近一个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 3 } },
                                              [_vm._v("三个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 4 } },
                                              [_vm._v("半年")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 5 } },
                                              [_vm._v("自定义")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[12]
                                                    .isSelected &&
                                                  _vm.precisionForm
                                                    .detailItem[12]
                                                    .timeRangeType == 5,
                                                expression:
                                                  "\n                        precisionForm.detailItem[12].isSelected &&\n                        precisionForm.detailItem[12].timeRangeType == 5\n                      ",
                                              },
                                            ],
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticClass: "marR20",
                                              attrs: {
                                                type: "datetimerange",
                                                "range-separator": "至",
                                                "start-placeholder": "开始日期",
                                                "end-placeholder": "结束日期",
                                                "default-time": [
                                                  "00:00:00",
                                                  "23:59:59",
                                                ],
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm:ss",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value: _vm.datePicker12,
                                                callback: function ($$v) {
                                                  _vm.datePicker12 = $$v
                                                },
                                                expression: "datePicker12",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[12].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[12].isSelected",
                                              },
                                            ],
                                            staticClass: "x-f",
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass:
                                                "inputWidthPrecision",
                                              attrs: {
                                                placeholder: "请输入内容",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.inputLimit(
                                                    $event,
                                                    4,
                                                    0,
                                                    _vm.precisionForm
                                                      .detailItem[12],
                                                    "begValue"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[12].begValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.precisionForm
                                                      .detailItem[12],
                                                    "begValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "precisionForm.detailItem[12].begValue",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "marR10 marL10" },
                                              [_vm._v("——")]
                                            ),
                                            _c("el-input", {
                                              staticClass:
                                                "inputWidthPrecision",
                                              attrs: {
                                                placeholder: "请输入内容",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.inputLimit(
                                                    $event,
                                                    4,
                                                    0,
                                                    _vm.precisionForm
                                                      .detailItem[12],
                                                    "endValue"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[12].endValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.precisionForm
                                                      .detailItem[12],
                                                    "endValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "precisionForm.detailItem[12].endValue",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "marL10" },
                                              [_vm._v("次")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "marB50 x-f" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[13]
                                                  .isSelected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[13],
                                                  "isSelected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[13].isSelected",
                                            },
                                          },
                                          [_vm._v("会员余额:")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[13].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[13].isSelected",
                                              },
                                            ],
                                            staticClass: "x-f",
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass:
                                                "inputWidthPrecision marL33",
                                              attrs: {
                                                type: "number",
                                                placeholder: "请输入内容",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.inputLimit(
                                                    $event,
                                                    8,
                                                    4,
                                                    _vm.precisionForm
                                                      .detailItem[13],
                                                    "begValue"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[13].begValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.precisionForm
                                                      .detailItem[13],
                                                    "begValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "precisionForm.detailItem[13].begValue",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "marR10 marL10" },
                                              [_vm._v(" —— ")]
                                            ),
                                            _c("el-input", {
                                              staticClass:
                                                "inputWidthPrecision",
                                              attrs: {
                                                placeholder: "请输入内容",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.inputLimit(
                                                    $event,
                                                    8,
                                                    4,
                                                    _vm.precisionForm
                                                      .detailItem[13],
                                                    "endValue"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[13].endValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.precisionForm
                                                      .detailItem[13],
                                                    "endValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "precisionForm.detailItem[13].endValue",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "marL10" },
                                              [_vm._v("元")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "x-f" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[14]
                                                  .isSelected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[14],
                                                  "isSelected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[14].isSelected",
                                            },
                                          },
                                          [_vm._v("会员积分:")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[14].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[14].isSelected",
                                              },
                                            ],
                                            staticClass: "x-f",
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass:
                                                "inputWidthPrecision marL33",
                                              attrs: {
                                                placeholder: "请输入内容",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.inputLimit(
                                                    $event,
                                                    7,
                                                    0,
                                                    _vm.precisionForm
                                                      .detailItem[14],
                                                    "begValue"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[14].begValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.precisionForm
                                                      .detailItem[14],
                                                    "begValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "precisionForm.detailItem[14].begValue",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "marR10 marL10" },
                                              [_vm._v(" —— ")]
                                            ),
                                            _c("el-input", {
                                              staticClass:
                                                "inputWidthPrecision",
                                              attrs: {
                                                placeholder: "请输入内容",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.inputLimit(
                                                    $event,
                                                    7,
                                                    0,
                                                    _vm.precisionForm
                                                      .detailItem[14],
                                                    "endValue"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[14].endValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.precisionForm
                                                      .detailItem[14],
                                                    "endValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "precisionForm.detailItem[14].endValue",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "marL10" },
                                              [_vm._v("分")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "x-x marB50" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: { disabled: _vm.disabled },
                                    model: {
                                      value: _vm.precisionForm.isUseVipMark,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.precisionForm,
                                          "isUseVipMark",
                                          $$v
                                        )
                                      },
                                      expression: "precisionForm.isUseVipMark",
                                    },
                                  },
                                  [_vm._v("按会员特征:")]
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.precisionForm.isUseVipMark,
                                        expression:
                                          "precisionForm.isUseVipMark",
                                      },
                                    ],
                                    staticClass: "filterCriteriaTwo",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "marB50 x-f" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[15]
                                                  .isSelected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[15],
                                                  "isSelected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[15].isSelected",
                                            },
                                          },
                                          [_vm._v("会员开卡门店:")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[15].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[15].isSelected",
                                              },
                                            ],
                                            staticClass: "marL33",
                                            attrs: {
                                              type: "text",
                                              disabled: _vm.disabled,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.getOpenShops(15)
                                              },
                                            },
                                          },
                                          [_vm._v("选择门店")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[15].shopItem
                                                    .length > 0,
                                                expression:
                                                  "\n                        precisionForm.detailItem[15].shopItem.length > 0\n                      ",
                                              },
                                            ],
                                            staticClass: "marL10",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.precisionForm.detailItem[15]
                                                  .shopItem.length
                                              ) + "种"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "marB50 x-f" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[16]
                                                  .isSelected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[16],
                                                  "isSelected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[16].isSelected",
                                            },
                                          },
                                          [_vm._v("会员年龄范围:")]
                                        ),
                                        _c(
                                          "el-radio-group",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[16].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[16].isSelected",
                                              },
                                            ],
                                            staticClass: "marL20",
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[16]
                                                  .ageRangeType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[16],
                                                  "ageRangeType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[16].ageRangeType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 1 } },
                                              [_vm._v("20~30")]
                                            ),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 2 } },
                                              [_vm._v("31~40")]
                                            ),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 3 } },
                                              [_vm._v("41~50")]
                                            ),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 4 } },
                                              [_vm._v("自定义")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[16]
                                                    .ageRangeType == 4,
                                                expression:
                                                  "precisionForm.detailItem[16].ageRangeType == 4",
                                              },
                                            ],
                                            staticClass: "x-f",
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass:
                                                "inputWidthPrecision marL33",
                                              attrs: {
                                                placeholder: "请输入内容",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.inputLimit(
                                                    $event,
                                                    3,
                                                    0,
                                                    _vm.precisionForm
                                                      .detailItem[16],
                                                    "begValue"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[16].begValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.precisionForm
                                                      .detailItem[16],
                                                    "begValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "precisionForm.detailItem[16].begValue",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "marR10 marL10" },
                                              [_vm._v(" —— ")]
                                            ),
                                            _c("el-input", {
                                              staticClass:
                                                "inputWidthPrecision",
                                              attrs: {
                                                placeholder: "请输入内容",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.inputLimit(
                                                    $event,
                                                    3,
                                                    0,
                                                    _vm.precisionForm
                                                      .detailItem[16],
                                                    "endValue"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[16].endValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.precisionForm
                                                      .detailItem[16],
                                                    "endValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "precisionForm.detailItem[16].endValue",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "marL10" },
                                              [_vm._v("岁")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "marB50 x-f" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[17]
                                                  .isSelected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[17],
                                                  "isSelected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[17].isSelected",
                                            },
                                          },
                                          [_vm._v("会员级别:")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[17].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[17].isSelected",
                                              },
                                            ],
                                            staticClass: "marL33",
                                            attrs: {
                                              type: "text",
                                              disabled: _vm.disabled,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.getOpenVipLevels(17)
                                              },
                                            },
                                          },
                                          [_vm._v("选择级别")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[17].vipLevelItem
                                                    .length > 0,
                                                expression:
                                                  "\n                        precisionForm.detailItem[17].vipLevelItem.length > 0\n                      ",
                                              },
                                            ],
                                            staticClass: "marL10",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.precisionForm.detailItem[17]
                                                  .vipLevelItem.length
                                              ) + "种"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "marB50 x-f" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[18]
                                                  .isSelected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[18],
                                                  "isSelected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[18].isSelected",
                                            },
                                          },
                                          [_vm._v("开卡时间:")]
                                        ),
                                        _c(
                                          "el-radio-group",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[18].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[18].isSelected",
                                              },
                                            ],
                                            staticClass: "marL20 marR20",
                                            attrs: {
                                              size: "medium",
                                              disabled: _vm.disabled,
                                            },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[18]
                                                  .timeRangeType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[18],
                                                  "timeRangeType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[18].timeRangeType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 1 } },
                                              [_vm._v("近一周")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 2 } },
                                              [_vm._v("近一个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 3 } },
                                              [_vm._v("三个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 4 } },
                                              [_vm._v("半年")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 5 } },
                                              [_vm._v("自定义")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[18]
                                                    .isSelected &&
                                                  _vm.precisionForm
                                                    .detailItem[18]
                                                    .timeRangeType == 5,
                                                expression:
                                                  "\n                        precisionForm.detailItem[18].isSelected &&\n                        precisionForm.detailItem[18].timeRangeType == 5\n                      ",
                                              },
                                            ],
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticClass: "marR20",
                                              attrs: {
                                                type: "datetimerange",
                                                "range-separator": "至",
                                                "start-placeholder": "开始日期",
                                                "end-placeholder": "结束日期",
                                                "default-time": [
                                                  "00:00:00",
                                                  "23:59:59",
                                                ],
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm:ss",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value: _vm.datePicker18,
                                                callback: function ($$v) {
                                                  _vm.datePicker18 = $$v
                                                },
                                                expression: "datePicker18",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "marB50 x-f" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[19]
                                                  .isSelected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[19],
                                                  "isSelected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[19].isSelected",
                                            },
                                          },
                                          [_vm._v("会员生日:")]
                                        ),
                                        _c(
                                          "el-radio-group",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[19].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[19].isSelected",
                                              },
                                            ],
                                            staticClass: "marL20 marR20",
                                            attrs: {
                                              size: "medium",
                                              disabled: _vm.disabled,
                                            },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[19]
                                                  .timeRangeType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[19],
                                                  "timeRangeType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[19].timeRangeType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 1 } },
                                              [_vm._v("近一周")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 2 } },
                                              [_vm._v("近一个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 3 } },
                                              [_vm._v("三个月")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 4 } },
                                              [_vm._v("半年")]
                                            ),
                                            _c(
                                              "el-radio-button",
                                              { attrs: { label: 5 } },
                                              [_vm._v("自定义")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[19]
                                                    .isSelected &&
                                                  _vm.precisionForm
                                                    .detailItem[19]
                                                    .timeRangeType == 5,
                                                expression:
                                                  "\n                        precisionForm.detailItem[19].isSelected &&\n                        precisionForm.detailItem[19].timeRangeType == 5\n                      ",
                                              },
                                            ],
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticClass: "marR20",
                                              attrs: {
                                                type: "datetimerange",
                                                "default-time": [
                                                  "00:00:00",
                                                  "23:59:59",
                                                ],
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm:ss",
                                                "range-separator": "至",
                                                "start-placeholder": "开始日期",
                                                "end-placeholder": "结束日期",
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value: _vm.datePicker19,
                                                callback: function ($$v) {
                                                  _vm.datePicker19 = $$v
                                                },
                                                expression: "datePicker19",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "x-f" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value:
                                                _vm.precisionForm.detailItem[20]
                                                  .isSelected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.precisionForm
                                                    .detailItem[20],
                                                  "isSelected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "precisionForm.detailItem[20].isSelected",
                                            },
                                          },
                                          [_vm._v("会员性别:")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.precisionForm
                                                    .detailItem[20].isSelected,
                                                expression:
                                                  "precisionForm.detailItem[20].isSelected",
                                              },
                                            ],
                                            staticClass: "marL15",
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  placeholder: "请选择性别",
                                                  filterable: "",
                                                  clearable: "",
                                                  size: "mini",
                                                  disabled: _vm.disabled,
                                                },
                                                model: {
                                                  value:
                                                    _vm.precisionForm
                                                      .detailItem[20]
                                                      .sexRangeType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.precisionForm
                                                        .detailItem[20],
                                                      "sexRangeType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "precisionForm.detailItem[20].sexRangeType",
                                                },
                                              },
                                              _vm._l(
                                                _vm.dict.type.sys_user_sex,
                                                function (dict) {
                                                  return _c("el-option", {
                                                    key: dict.value,
                                                    attrs: {
                                                      label: dict.label,
                                                      value: parseInt(
                                                        dict.value
                                                      ),
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "cardTitleCom",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeStep === 1,
                      expression: "activeStep === 1",
                    },
                  ],
                  attrs: { cardTitle: "目标对象列表" },
                },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c(
                      "div",
                      { staticClass: "tableContent" },
                      [
                        _c("div", { staticClass: "marB10" }),
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingVip,
                                expression: "loadingVip",
                              },
                            ],
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.vipList,
                              border: "",
                              "max-height": "500",
                              height: "500",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                prop: "userId",
                                width: "80",
                                type: "index",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var $index = ref.$index
                                    var row = ref.row
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              $index +
                                                1 +
                                                (_vm.queryOptions.pageNum - 1) *
                                                  _vm.queryOptions.pageSize
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "会员编号",
                                width: "160",
                                align: "center",
                                prop: "vipNo",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "会员名称",
                                width: "160",
                                align: "center",
                                prop: "vipName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "会员手机号",
                                align: "center",
                                width: "196",
                                prop: "tel",
                              },
                            }),
                            _c("el-table-column"),
                          ],
                          1
                        ),
                        _vm.refreshPinigation
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  padding: "20px",
                                },
                              },
                              [
                                _c("el-pagination", {
                                  attrs: {
                                    background: "",
                                    "page-sizes": [10, 20, 30],
                                    layout:
                                      "total, sizes, prev, pager, next, jumper",
                                    total: _vm.total,
                                  },
                                  on: {
                                    "size-change": _vm.handleSizeChangeFun,
                                    "current-change":
                                      _vm.handleCurrentChangeFun,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "cardTitleCom",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeStep == 2 || _vm.activeStep == 3,
                      expression: "activeStep == 2 || activeStep == 3",
                    },
                  ],
                  attrs: { cardTitle: "礼包领取设置" },
                },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c(
                      "div",
                      { staticClass: "modifyType" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "用户领取方式",
                              prop: "billNo",
                              "label-width": "96px",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                disabled: true,
                                placeholder: "直接到账",
                                size: "mini",
                              },
                              model: {
                                value: _vm.precisionForm.billNo2,
                                callback: function ($$v) {
                                  _vm.$set(_vm.precisionForm, "billNo2", $$v)
                                },
                                expression: "precisionForm.billNo2",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: _vm.disabled },
                                model: {
                                  value: _vm.precisionForm.effectMode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.precisionForm,
                                      "effectMode",
                                      $$v
                                    )
                                  },
                                  expression: "precisionForm.effectMode",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("立即生效"),
                                ]),
                                _c("el-radio", { attrs: { label: 2 } }, [
                                  _vm._v("指定时间生效"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.precisionForm.effectMode == 2,
                                    expression: "precisionForm.effectMode == 2",
                                  },
                                ],
                                staticClass: "marL15",
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    type: "datetime",
                                    "picker-options": _vm.effectPickerOptions,
                                    placeholder: "选择日期",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    size: "mini",
                                  },
                                  on: { change: _vm.handleChangeEffectPicker },
                                  model: {
                                    value: _vm.precisionForm.effectBegTime,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.precisionForm,
                                        "effectBegTime",
                                        $$v
                                      )
                                    },
                                    expression: "precisionForm.effectBegTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "cardTitleCom",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeStep == 2 || _vm.activeStep == 3,
                      expression: "activeStep == 2 || activeStep == 3",
                    },
                  ],
                  attrs: { cardTitle: "礼包内容" },
                },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c("div", { staticClass: "packageContents" }, [
                      _c(
                        "div",
                        {
                          staticClass: "marB20 x-f",
                          staticStyle: { height: "30px" },
                        },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: { disabled: _vm.disabled },
                              model: {
                                value: _vm.precisionForm.isLargessScore,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.precisionForm,
                                    "isLargessScore",
                                    $$v
                                  )
                                },
                                expression: "precisionForm.isLargessScore",
                              },
                            },
                            [_vm._v("赠送积分:")]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.precisionForm.isLargessScore,
                                  expression: "precisionForm.isLargessScore",
                                },
                              ],
                            },
                            [
                              _c("el-input", {
                                staticClass: "inputWidth marL15 marR15",
                                attrs: {
                                  disabled: _vm.disabled,
                                  placeholder: "赠送积分",
                                  size: "mini",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.inputLimit(
                                      $event,
                                      7,
                                      0,
                                      _vm.precisionForm,
                                      "largessScore"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.precisionForm.largessScore,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.precisionForm,
                                      "largessScore",
                                      $$v
                                    )
                                  },
                                  expression: "precisionForm.largessScore",
                                },
                              }),
                              _c("span", { staticClass: "fontS14" }, [
                                _vm._v("分"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: { disabled: _vm.disabled },
                              model: {
                                value: _vm.precisionForm.isLargessCoupon,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.precisionForm,
                                    "isLargessCoupon",
                                    $$v
                                  )
                                },
                                expression: "precisionForm.isLargessCoupon",
                              },
                            },
                            [_vm._v("赠送优惠券:")]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.precisionForm.isLargessCoupon,
                                  expression: "precisionForm.isLargessCoupon",
                                },
                              ],
                              staticClass: "marT10",
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "marB10",
                                  attrs: {
                                    size: "mini",
                                    disabled: _vm.disabled,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEvent("openCouponDialog")
                                    },
                                  },
                                },
                                [_vm._v("选择优惠券")]
                              ),
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "tooltip-effect": "dark",
                                    data: _vm.precisionForm.largessCouponItem,
                                    border: "",
                                    "max-height": "500",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "序号",
                                      align: "center",
                                      prop: "userId",
                                      width: "80",
                                      type: "index",
                                    },
                                  }),
                                  !_vm.disabled
                                    ? _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "操作",
                                          width: "80",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "text",
                                                        size: "mini",
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.deleteRow(
                                                            scope.$index,
                                                            _vm.precisionForm
                                                              .largessCouponItem
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-remove operateDel",
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          223342201
                                        ),
                                      })
                                    : _vm._e(),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "优惠券名称",
                                      width: "160",
                                      align: "center",
                                      prop: "couponCaseName",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "赠送数量(单个会员)",
                                      align: "center",
                                      width: "196",
                                      prop: "couponEmitQty",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "赠送方式",
                                      align: "center",
                                      prop: "couponEmitModeName",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "生效方式",
                                      align: "center",
                                      prop: "couponEffectModeName",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("selectGoods", {
        ref: "selectGoods",
        attrs: { OpenGoods: _vm.openGoods },
        on: {
          "update:OpenGoods": function ($event) {
            _vm.openGoods = $event
          },
          "update:open-goods": function ($event) {
            _vm.openGoods = $event
          },
          getGoodS: _vm.getGoodsCom,
        },
      }),
      _c("selectCategory", {
        attrs: { OpenCategory: _vm.openCategory },
        on: {
          "update:OpenCategory": function ($event) {
            _vm.openCategory = $event
          },
          "update:open-category": function ($event) {
            _vm.openCategory = $event
          },
          categoryList: _vm.categoryListCom,
        },
      }),
      _c("selectBrand", {
        attrs: { OpenBrand: _vm.openBrand },
        on: {
          "update:OpenBrand": function ($event) {
            _vm.openBrand = $event
          },
          "update:open-brand": function ($event) {
            _vm.openBrand = $event
          },
          brandList: _vm.brandListCom,
        },
      }),
      _c("shopInfo", {
        attrs: { OpenShopInfo: _vm.openShopInfo },
        on: {
          "update:OpenShopInfo": function ($event) {
            _vm.openShopInfo = $event
          },
          "update:open-shop-info": function ($event) {
            _vm.openShopInfo = $event
          },
          shopInfoList: _vm.shopInfoListCom,
        },
      }),
      _c("vipLeve", {
        attrs: { OpenVipLeve: _vm.openVipLeve },
        on: {
          "update:OpenVipLeve": function ($event) {
            _vm.openVipLeve = $event
          },
          "update:open-vip-leve": function ($event) {
            _vm.openVipLeve = $event
          },
          vipLeveList: _vm.vipLeveListCom,
        },
      }),
      _c("selectCoupon", {
        attrs: {
          OpenCoupon: _vm.openCoupon,
          isRadio: true,
          isSuingEditorIsRadio: true,
        },
        on: {
          "update:OpenCoupon": function ($event) {
            _vm.openCoupon = $event
          },
          "update:open-coupon": function ($event) {
            _vm.openCoupon = $event
          },
          getCoupons: function ($event) {
            return _vm.getPitchData($event, "coupon")
          },
        },
      }),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }