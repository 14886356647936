var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("TablePage", {
        ref: "tablePage",
        on: { handleEvent: _vm.handleEvent },
        model: {
          value: _vm.options,
          callback: function ($$v) {
            _vm.options = $$v
          },
          expression: "options",
        },
      }),
      _c("c-dialog", {
        attrs: {
          title: _vm.title,
          width: 700,
          showDialog: _vm.dialogFormVisible,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.dialogLoading,
                        expression: "dialogLoading",
                      },
                    ],
                    ref: "ruleForm",
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rules,
                      "label-width": "100px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "次卡编码", prop: "timesCardNo" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: "20",
                            placeholder: "请输入次卡编码",
                            size: "mini",
                          },
                          model: {
                            value: _vm.form.timesCardNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "timesCardNo", $$v)
                            },
                            expression: "form.timesCardNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "次卡名称", prop: "timesCardName" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: "50",
                            placeholder: "请输入次卡名称",
                            size: "mini",
                          },
                          model: {
                            value: _vm.form.timesCardName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "timesCardName", $$v)
                            },
                            expression: "form.timesCardName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "次卡类型",
                          prop: "timesCardType",
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.form.timesCardType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "timesCardType", $$v)
                              },
                              expression: "form.timesCardType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("商品次卡"),
                            ]),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("套餐次卡"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.form.timesCardType == 1
                      ? _c(
                          "div",
                          { staticClass: "x-f" },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "商品", prop: "goodsId" } },
                              [
                                _c("SelectRemote", {
                                  attrs: {
                                    option: _vm.$select({
                                      key: "listGoods",
                                      option: {
                                        option: {
                                          clearable: true,
                                          buttons: [
                                            {
                                              type: "more",
                                              option: {
                                                title: "选择商品",
                                                width: 1250,
                                                type: "TreeAndTable",
                                                formData: _vm.$dialog({
                                                  key: "goods",
                                                  option: {
                                                    table: Object.assign(
                                                      {},
                                                      _vm.$dialog({
                                                        key: "goods",
                                                      }).table,
                                                      {
                                                        mutiSelect: false,
                                                        radioSelect: true,
                                                      }
                                                    ),
                                                  },
                                                }),
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    }).option,
                                  },
                                  on: { selectChange: _vm.selectGoods },
                                  model: {
                                    value: _vm.form.goodsId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "goodsId", $$v)
                                    },
                                    expression: "form.goodsId",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "单位", prop: "unitId" } },
                              [
                                _c("SelectLocal", {
                                  attrs: {
                                    option: {
                                      data: _vm.form.units,
                                      value: "unitId",
                                      label: "unitName",
                                    },
                                  },
                                  model: {
                                    value: _vm.form.unitId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "unitId", $$v)
                                    },
                                    expression: "form.unitId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "x-f" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "促销方案",
                                  prop: "promoCaseBillId",
                                },
                              },
                              [
                                _c("SelectRemote", {
                                  attrs: {
                                    option: _vm.$select({
                                      key: "listPromo",
                                      option: {
                                        option: {
                                          clearable: true,
                                          remoteBody: {
                                            promoTypes: [10],
                                            executionStatuss: [1, 2],
                                            isStops: [false],
                                          },
                                        },
                                      },
                                    }).option,
                                  },
                                  on: { selectChange: _vm.selectCase },
                                  model: {
                                    value: _vm.form.promoCaseBillId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "promoCaseBillId", $$v)
                                    },
                                    expression: "form.promoCaseBillId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "可用次数", prop: "totalCount" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: "4",
                            placeholder: "请输入可用次数",
                            size: "mini",
                            type: "number",
                          },
                          model: {
                            value: _vm.form.totalCount,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "totalCount", $$v)
                            },
                            expression: "form.totalCount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "销售价格", prop: "salePrice" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: "8",
                            placeholder: "请输入销售价格",
                            size: "mini",
                            type: "number",
                          },
                          model: {
                            value: _vm.form.salePrice,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "salePrice", $$v)
                            },
                            expression: "form.salePrice",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "*有效时间",
                          prop: "limitVaildTimeType",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            on: { change: _vm.limitVaildTimeChange },
                            model: {
                              value: _vm.form.limitVaildTimeType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "limitVaildTimeType", $$v)
                              },
                              expression: "form.limitVaildTimeType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("不限制"),
                            ]),
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("限制使用天数"),
                            ]),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("固定使用日期"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.form.limitVaildTimeType == 1
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "有效天数", prop: "limitDays" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "4",
                                    placeholder: "请输入有效天数",
                                    size: "mini",
                                    type: "number",
                                  },
                                  model: {
                                    value: _vm.form.limitDays,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "limitDays", $$v)
                                    },
                                    expression: "form.limitDays",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.form.limitVaildTimeType == 2
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "*有效日期" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                "unlink-panels": "",
                                size: "mini",
                                type: "daterange",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                "value-format": "yyyy-MM-dd",
                                "picker-options": _vm.disableOptions,
                              },
                              model: {
                                value: _vm.datetime,
                                callback: function ($$v) {
                                  _vm.datetime = $$v
                                },
                                expression: "datetime",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "使用限制",
                          prop: "limitUseCountType",
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.form.limitUseCountType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "limitUseCountType", $$v)
                              },
                              expression: "form.limitUseCountType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("不限制"),
                            ]),
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("每日限制次数"),
                            ]),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("每周限制次数"),
                            ]),
                            _c("el-radio", { attrs: { label: 3 } }, [
                              _vm._v("每月限制次数"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.form.limitUseCountType != 0
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "限制次数", prop: "limitCount" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "4",
                                placeholder: "请输入限制次数",
                                size: "mini",
                                type: "number",
                              },
                              model: {
                                value: _vm.form.limitCount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "limitCount", $$v)
                                },
                                expression: "form.limitCount",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "是否门店销售",
                          prop: "isAllowOfflineShop",
                          size: "mini",
                          "label-width": "120px",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.form.isAllowOfflineShop,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "isAllowOfflineShop", $$v)
                              },
                              expression: "form.isAllowOfflineShop",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "是否线上销售",
                          prop: "isAllowOnlineShop",
                          size: "mini",
                          "label-width": "120px",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.form.isAllowOnlineShop,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "isAllowOnlineShop", $$v)
                              },
                              expression: "form.isAllowOnlineShop",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            placeholder: "备注长度介于 1 和 80 字符之间",
                            maxlength: "80",
                            autosize: { minRows: 2, maxRows: 4 },
                          },
                          model: {
                            value: _vm.form.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "remark", $$v)
                            },
                            expression: "form.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.cancel } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.handSubmit } },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }