<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>
<script>
import TablePage from '@/components/tablePage' //表格页面组件
import {
  vipMarketingTimesCardConsume,
  vipMarketingTimesCardConsumeSummary
} from '@/api/marketing/timesCard/consumeQuery'
export default {
  name: 'consumeQuery',
  components: {
    TablePage
  },
  data() {
    return {
      options: {
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        title: '会员次卡消费查询',
        getListApi: vipMarketingTimesCardConsume,
        getSummaryApi: vipMarketingTimesCardConsumeSummary,
        listNo: true, // 序号
        exportOption: {
          show: true,
          fastExportUrl: '/api/system/marketing/times/card/reportForms/vipMarketingTimesCardConsumeExport',
          exportName: '会员次卡消费查询'
        },
        //搜索
        search: [
          {
            label: '单据日期',
            type: 'datetimerange',
            model: '',
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            tip: '门店编号/门店名称',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'vipNos', label: '会员卡号' },
              { filter: 'vipNames', label: '会员名称' },
              { filter: 'timesCardNos', label: '次卡编码' },
              { filter: 'timesCardNames', label: '次卡名称' }
            ]
          },
          this.$select({
            key: 'vip',
            option: {
              filter: 'vipIds',
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择会员',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'vip' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'listShop',
            option: {
              filter: 'shopIds',
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择门店',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'shop' })
                    }
                  }
                ]
              }
            }
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        //表格
        columns: [
          {
            prop: 'vipNo',
            label: '会员卡号',
            minWidth: 180,
            align: 'center'
          },
          {
            prop: 'vipName',
            label: '会员名称',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'timesCardNo',
            label: '次卡编码',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'timesCardName',
            label: '次卡名称',
            minWidth: 180,
            align: 'center'
          },
          {
            prop: 'goodsName2',
            label: '商品/套餐',
            minWidth: 180,
            align: 'center',
             formatter: (propValue, row) => {
              return row.timesCardType == 1
                ? row.goodsName
                : row.timesCardType == 2
                  ? row.promoCaseBillName
                  : "";
            }
          },
          {
            prop: 'consumeTime',
            label: '消费时间',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'consumeMoney',
            label: '消费金额',
            minWidth: 120,
            align: 'center',
            sortable: true
          },
          {
            prop: 'consumeCount',
            label: '消费次数',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'retailBillNo',
            label: '小票号',
            minWidth: 180,
            align: 'center'
          },
          {
            prop: 'cashierNo',
            label: '收银员编号',
            align: 'center',
            minWidth: 120
          },
          {
            prop: 'cashierName',
            label: '收银员名称',
            align: 'center',
            minWidth: 120
          },
          {
            prop: 'shopName',
            label: '消费门店',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'remark',
            label: '备注',
            minWidth: 130,
            align: 'center'
          }
        ],
        summary: ['consumeMoney', 'consumeCount']
      }
    }
  }
}
</script>
