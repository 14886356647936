<template>
  <div class="wrap" id="wrap">
    <!-- 搜索信息 -->
    <cardTitleCom cardTitle="搜索" id="search-card">
      <template slot="cardContent">
        <!-- 整体盒子 -->
        <div class="searchMessage">
          <span class="fS14MR10">短信类型</span>
          <el-select
            class="marR15 inputWidthM"
            v-model="queryParams.remindTypeIds"
            placeholder="全部"
            multiple
            collapse-tags
            filterable
            clearable
            size="mini"
          >
            <el-option
              v-for="dict in dict.type.remind_type_id"
              :key="dict.value"
              :label="dict.label"
              :value="dict.value"
            />
          </el-select>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="getList"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh" size="mini" @click="refresh($event)"
            >重置</el-button
          >
        </div>
      </template>
    </cardTitleCom>
    <!-- 表格商品信息卡片 -->
    <cardTitleCom cardTitle="单据信息" class="content">
      <template slot="cardContent">
        <!-- 整体盒子 -->
        <div class="tableContent">
          <el-table
            v-loading="loadingTable"
            tooltip-effect="dark"
            :data="tableData"
            border
            :max-height="tableHeight"
            :height="tableHeight"
            ref="multipleTable"
          >
            <el-table-column
              label="序号"
              align="center"
              prop="userId"
              width="80"
              type="index"
            />
            <el-table-column label="类型" align="center" prop="remindTypeId">
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  @click="handleDetail(scope.row)"
                  target="_blank"
                >
                  <dict-tag
                    :options="dict.type.remind_type_id"
                    :value="scope.row.remindTypeId"
                  />
                </el-link>
              </template>
            </el-table-column>
            <el-table-column
              label="计费条数"
              align="center"
              prop="sendChargeCount"
            />
            <el-table-column
              label="发送条数"
              align="center"
              prop="sumSendCount"
            />
          </el-table>
          <pagination
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList"
          />
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import { listStatisticsAPI } from "@/api/marketing/sms/history"; //短信历史
export default {
  name: "history",
  dicts: ["remind_type_id"],
  components: { cardTitleCom },
  data() {
    return {
      //表格遮罩
      loadingTable: false,
      // 总条数
      total: 0,
      //选择对象遮罩
      loadingTable: false,
      //表格
      tableData: [],
      //查询
      queryParams: {
        pageNum: 1,
        pageSize: 15,
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40,
    };
  },
  created() {
    this.getList();
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 90;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap")?.clientHeight;
      this.searchH = document.getElementById("search-card")?.clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.vivwH = document.getElementById("wrap")?.clientHeight;
      this.searchH = document.getElementById("search-card")?.clientHeight;
    },
    //跳转详情
    handleDetail(row) {
      this.$router.push({
        name: "historyDetail",
        query: {
          paramsObj: {
            bookType: 2,
            remindTypeId: row.remindTypeId,
            pageNum: 1,
            pageSize: 15,
          },
          type: "Update",
        },
      });
    },
    /** 查询表格列表 */
    async getList() {
      this.loadingTable = true;
      const res = await listStatisticsAPI(this.queryParams);
      this.tableData = res.rows;
      this.total = res.total;
      this.loadingTable = false;
    },
    //重置按钮
    refresh(e) {
      let target = e.target;
      if (target.nodeName == "I" || target.nodeName == "SPAN") {
        target = e.target.parentNode;
      }
      target.blur();
      this.queryParams = { pageNum: 1, pageSize: 15 };
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 1px 10px;
  background-color: #dbdbdb;
  height: calc(100vh - 84px);
  .content {
    height: calc(100% - 94px);
    //源单信息表格
    .tableContent {
      margin: 6px 10px 10px 10px;
    }
  }
  // 搜索
  .searchMessage {
    padding: 10px 10px 10px 20px;
    //单据日期
    ::v-deep .el-date-editor--datetimerange.el-input,
    .el-date-editor--datetimerange.el-input__inner {
      width: 355px;
    }
  }
}
</style>
